import React, { useEffect, useState } from 'react'
import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { isNumber } from 'lodash'

interface FormattedInputNumberProps extends InputNumberProps {
  percentage?: boolean
  block?: boolean
  value?: string | number | null
}

export const FormattedInputNumber = React.forwardRef(
  (
    { percentage, step, precision, min, max, block, value = 0, onChange, ...restProps }: FormattedInputNumberProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const factor = percentage ? 100 : 1
    const [internalValue, setInternalValue] = useState(
      Math.round((Number(value) * factor + Number.EPSILON) * 100) / 100
    )
    const handleChange = (inputValue: string | number | null) => {
      if (isNumber(inputValue)) {
        setInternalValue(inputValue)
        onChange?.(inputValue / factor)
      }
    }

    useEffect(() => {
      setInternalValue(Math.round((Number(value) * factor + Number.EPSILON) * 100) / 100)
    }, [value])

    return (
      <InputNumber
        ref={ref}
        step={step}
        min={min}
        max={max}
        value={internalValue}
        decimalSeparator=","
        onChange={handleChange}
        precision={precision}
        style={block ? { width: '100%' } : undefined}
        {...restProps}
      />
    )
  }
)
