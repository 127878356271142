import { SET_APP_CONFIGS } from '../types'
import { FinancialStatementConfigActionTypes, SET_FINANCIAL_STATEMENT_CONFIG } from './types'

const initialState = {}

const financialStatementConfigReducer = (state = initialState, action: FinancialStatementConfigActionTypes) => {
  switch (action.type) {
    case SET_FINANCIAL_STATEMENT_CONFIG:
      return action.payload
    case SET_APP_CONFIGS:
      return action.payload.financialStatement
    default:
      return state
  }
}

export default financialStatementConfigReducer
