import { SET_APP_CONFIGS } from '../types'
import { BudgetingConfigActionTypes, BUDGETING_CONFIG } from './types'

const initialState = {}

const BudgetingConfigReducer = (state = initialState, action: BudgetingConfigActionTypes) => {
  switch (action.type) {
    case BUDGETING_CONFIG:
      return action.payload
    case SET_APP_CONFIGS:
      return action.payload.budgeting
    default:
      return state
  }
}

export default BudgetingConfigReducer
