import { SET_APP_CONFIGS } from '../types'
import { LiabilityMappingActionTypes, LIABILITY_MAPPING, LiabilityMapping } from './types'

const initialState: LiabilityMapping = {}

const LiabilityMappingReducer = (state = initialState, action: LiabilityMappingActionTypes) => {
  switch (action.type) {
    case LIABILITY_MAPPING:
      return action.payload
    case SET_APP_CONFIGS:
      return action.payload.liabilityMapping
    default:
      return state
  }
}

export default LiabilityMappingReducer
