import React from 'react'
import { Select, Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { updateExchangeRate } from '../../../../../redux/context/exchangeRates/actions'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { CustomExchangeRateSeries, CustomExchangeRateSeriesType, CustomExchangeRateSeriesWithCompany } from '../types'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { useBackend } from '../../../../../services/backend'
import { exchangeRatesCompanySelector } from '../../../../../redux/context/exchangeRates/selectors'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'

type DataBody = Pick<CustomExchangeRateSeries, 'groupId' | 'companyId' | 'type' | 'baseCurrency' | 'currency'>

const ExchangeRateTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const groupId = useSelector(contextCompanyIdSelector)!

  const exchangeRates = useSelector(exchangeRatesCompanySelector)
  const { exchangeRates: exchangeRatesLoading } = useSelector(loadingSelector)
  const exchangeRateEditRequest = useBackend('api/companies/{groupId}/group/exchange-rates/{seriesId}')
  const exchangeRateCreateRequest = useBackend('api/companies/{groupId}/group/exchange-rates')

  const handleSave = async (
    type: CustomExchangeRateSeriesType,
    { companyId, baseCurrency, currency, id }: CustomExchangeRateSeriesWithCompany
  ) => {
    try {
      const data: DataBody = {
        groupId,
        companyId,
        baseCurrency,
        currency,
        type
      }

      const requestData = {
        urlParams: { groupId, seriesId: id },
        body: { data }
      }

      const updatedData = id
        ? await exchangeRateEditRequest.put(requestData)
        : await exchangeRateCreateRequest.post(requestData)

      dispatch(updateExchangeRate(updatedData))

      dispatch(
        notificationAction({
          type: 'success',
          message: t('exchangeRatesPage:updateSuccess')
        })
      )
    } catch (error) {
      dispatch(
        notificationAction({
          type: 'error',
          message: t('exchangeRatesPage:updateError')
        })
      )
    }
  }

  const columns: ColumnProps<CustomExchangeRateSeriesWithCompany>[] = [
    {
      dataIndex: ['company', 'name'],
      title: t('global:company'),
      render(value) {
        if (!value) {
          return t('errorMessage:COMPANY_UNAUTHORIZED')
        }
        return value
      }
    },
    {
      dataIndex: 'currency',
      title: t('global:currency'),
      render(currency, { baseCurrency }) {
        return `${currency}/${baseCurrency}`
      }
    },
    {
      dataIndex: 'type',
      title: t('exchangeRatesPage:exchangeRateType'),
      render(value, record) {
        return (
          <Select
            size="small"
            value={value}
            disabled={!record.company?.name}
            style={{ width: 120 }}
            onChange={type => handleSave(type, record)}
            options={Object.values(CustomExchangeRateSeriesType).map(val => ({
              value: val,
              label: t(`global:${val}`)
            }))}
          />
        )
      }
    },
    {
      dataIndex: 'edit',
      title: t('global:actions'),
      render: (value, record) => {
        if (!record.company?.name) return null
        return (
          <Link to="/settings/company/group/exchangeRate/edit" state={record}>
            {record.type === CustomExchangeRateSeriesType.manual ? t('global:edit') : 'Tarkastele'}
          </Link>
        )
      }
    }
  ]

  return (
    <Table
      loading={exchangeRatesLoading || exchangeRateEditRequest.loading || exchangeRateCreateRequest.loading}
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={exchangeRates}
    />
  )
}

export default ExchangeRateTable
