// import { combineReducers } from 'redux'
// import contractProductsReducer from './contractProducts/reducer'
// import investmentFundingSourceReducer from './investmentFundingSources/reducer'
// import keyFiguresReducer from './keyFigures/reducer'
// import loanRepaymentMethodsReducer from './loanRepaymentMethods/reducer'
// import statementRowsReducer from './statementRows/reducer'
// import industryReducer from './industries/reducers'
// import permissionsReducer from './permissions/reducer'
import { Entities, SET_ENTITIES } from './types'

// const entitiesReducer = combineReducers({
//   contractProducts: contractProductsReducer,
//   investmentFundingSources: investmentFundingSourceReducer,
//   keyFigures: keyFiguresReducer,
//   loanRepaymentMethods: loanRepaymentMethodsReducer,
//   statementRows: statementRowsReducer,
//   industries: industryReducer,
//   permissions: permissionsReducer
// })

// TODO: Check entities reducers
const initialState: Entities = {
  contractProducts: {},
  investmentFundingSources: [],
  keyFigures: [],
  loanRepaymentMethods: [],
  statementRows: [],
  industries: [],
  permissions: []
}

const entitiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ENTITIES:
      return action.payload
    default:
      return state
  }
}

export default entitiesReducer
