import { Affix } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'
import styled from 'styled-components'

interface ToolbarProps {
  visible?: boolean
  style?: React.CSSProperties
  offsetBottom?: number
  offsetTop?: number
  justifyContent?: React.CSSProperties['justifyContent']
}

const StyledToolbar = styled.div<{
  justifyContent?: React.CSSProperties['justifyContent']
}>`
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 0px;
  transition: all 0.3s ease 0s;
  justify-content: ${({ justifyContent }) => justifyContent};
  background-color: rgb(255, 255, 255);

  &.affixed {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 8px;
    margin: -8px;
    border-radius: 6px;
  }
`

const ToolBar: React.FC<ToolbarProps> = ({
  visible = true,
  children,
  offsetBottom,
  offsetTop = 70,
  justifyContent,
  style
}) => {
  const [affixed, setAffixed] = useState(false)

  if (!visible) return null

  return (
    <Affix
      offsetBottom={offsetBottom}
      offsetTop={offsetTop}
      onChange={status => {
        setAffixed(!!status)
      }}
    >
      <StyledToolbar className={classNames({ affixed })} justifyContent={justifyContent} style={style}>
        {children}
      </StyledToolbar>
    </Affix>
  )
}

export default ToolBar
