import { Button, Result } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { currentUserHomepageSelector } from '../../redux/session/currentUser/selectors'

// TODO: Käännökset erityyppisille erroreille
function BoundaryFallback({ error, resetErrorBoundary }: any) {
  const homepage = useSelector(currentUserHomepageSelector)
  const navigate = useNavigate()

  const goHome = () => {
    const url = homepage
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    resetErrorBoundary()
    navigate(url)
  }

  const extra = () => (
    <Button type="primary" onClick={goHome}>
      Back Home
    </Button>
  )

  if (error.status === 401) {
    return (
      <Result
        status="403"
        title="Unathorized"
        subTitle="Sorry, you are not authorized to access this page."
        extra={extra()}
      />
    )
  }

  return <Result status="error" title={error.message} subTitle={error.cause} extra={extra()} />
}
export default BoundaryFallback
