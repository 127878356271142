import Bugsnag from '@bugsnag/js'
import { useSelector } from 'react-redux'
import { getContractProductsRequest } from '../../entities/contractProducts/actions'
import { getInvestmentFundingSourceRequest } from '../../entities/investmentFundingSources/actions'
import { getKeyFiguresRequest } from '../../entities/keyFigures/actions'
import { getLoanRepaymentMethods } from '../../entities/loanRepaymentMethods/actions'
import { getStatementRowsRequest } from '../../entities/statementRows/actions'
import { POST, PostRequestAction } from '../../middleware/types'
import { getCurrentUserRequest } from '../currentUser/actions'
import { getIndustries } from '../../entities/industries/actions'
import { Authentication, Credentials, LOGOUT, LogoutAction, SetTokenAction, SET_TOKEN } from './types'
import { getPermissionsRequest } from '../../entities/permissions/actions'
import { getCashFlowStatementConfigRequest } from '../../config/cashFlowStatement/actions'
import { getFinancialStatementConfigRequest } from '../../config/financialStatement/actions'
import { getBudgetingConfigRequest } from '../../config/budgeting/actions'
import { currentUserSelector } from '../currentUser/selectors'
import { getBudgetingLiabilityMappingRequest } from '../../config/liabilityMapping/actions'

export const logoutAction = (): LogoutAction => ({
  type: LOGOUT
})

export const setToken = (payload: Authentication): SetTokenAction => ({
  type: SET_TOKEN,
  payload
})

const loginSuccess = (payload: Authentication) => {
  return (dispatch: any) => {
    dispatch(setToken(payload))
    dispatch(getCurrentUserRequest())

    dispatch(getPermissionsRequest())
    dispatch(getKeyFiguresRequest())
    dispatch(getStatementRowsRequest())
    dispatch(getLoanRepaymentMethods())
    dispatch(getContractProductsRequest())
    dispatch(getInvestmentFundingSourceRequest())
    dispatch(getIndustries())

    dispatch(getFinancialStatementConfigRequest())
    dispatch(getCashFlowStatementConfigRequest())
    dispatch(getBudgetingConfigRequest())
    dispatch(getBudgetingLiabilityMappingRequest())

    const { email } = useSelector(currentUserSelector)
    Bugsnag.setUser(email)
  }
}

export const login = (credentials: Credentials): PostRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: POST,
    url: 'api/login',
    data: credentials,
    success: (payload: Authentication) => loginSuccess(payload)
  },
  meta: {
    type: 'LOGIN'
  }
})
