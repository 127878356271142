import React, { useEffect, useMemo, useState } from 'react'
import { BarChartOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Card, Result, Space, Switch, Tabs, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { getKeyFigureStatementRequest } from '../../../redux/context/reports/actions'
import {
  groupedKeyFigureStatementSelector,
  subscribedKeyFigureStatementSelector
} from '../../../redux/context/reports/selectors'
import { Report, ReportRow } from '../../../redux/context/reports/types'
import { getSubscribedKeyFiguresRequest } from '../../../redux/context/subscribedKeyFigures/actions'
import { keyFigureReferenceValues } from './components/referenceValueTable/config'
import { contextRequest } from '../../../redux/context/actions'
import KatexModal from './components/KatexModal'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { errorSelector } from '../../../redux/error/selectors'
import KeyFigureChart from './components/keyFigureChart/KeyFigureChart'
import { drawableKeyFiguresSelector } from '../../../redux/entities/keyFigures/selectors'
import { hasData } from '../../../components/Table/utils'
import { loadingSelector } from '../../../redux/loading/selectors'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import KeyFigureTable from './components/KeyFigureTable'
import './_KeyFigures.sass'
import { AppDispatch } from '../../../redux/store'
import { appLanguageSelector } from '../../../redux/app/selectors'

const KeyFigures: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)!
  const loading = useSelector(loadingSelector)
  const filters = useSelector(filtersSelector)
  const { budgetingScenario, dimensions, periodGroups } = useSelector(filtersSelector)
  const errors = useSelector(errorSelector)

  const groupedKeyFigureStatement = useSelector(groupedKeyFigureStatementSelector)
  const subscribedKeyFigureStatement = useSelector(subscribedKeyFigureStatementSelector) || []
  const finalKeyFigureReferenceValues = keyFigureReferenceValues
  const drawableKeyFigures = useSelector(drawableKeyFiguresSelector)
  const drawableKeyFigureIds = drawableKeyFigures.map(keyFigure => keyFigure.id)
  const language = useSelector(appLanguageSelector)

  const [cumulative, setCumulative] = useState(false)

  useEffect(() => {
    if (companyId) {
      dispatch(contextRequest(getKeyFigureStatementRequest))
      dispatch(contextRequest(getSubscribedKeyFiguresRequest))
    }
  }, [companyId, budgetingScenario, dimensions, periodGroups, language])

  const subscribedKeyFigureReferenceValues = finalKeyFigureReferenceValues.filter(ref =>
    subscribedKeyFigureStatement.map(kf => kf.id).includes(ref.keyFigureId)
  )

  const cumulativesEnabled = (keyFigureGroup: string) => {
    return !!(keyFigureGroup === 'PROFITABILITY_ABSOLUTE' && filters.periodGroup)
  }

  // Keyfigure Group page contents
  const keyFigurePageContent = (reportRows: Report, keyFigureGroup: string) => {
    const referenceValues = finalKeyFigureReferenceValues.filter(ref =>
      reportRows.map(kf => kf.id).includes(ref.keyFigureId)
    )
    const showChart = !!reportRows.filter(
      (reportRow: ReportRow) => drawableKeyFigureIds.includes(reportRow.id) && hasData(reportRow)
    ).length

    return (
      <div>
        {cumulativesEnabled(keyFigureGroup) && (
          <Space>
            {t('keyFigurePage:cumulative')}
            <Switch
              checked={cumulative}
              onChange={(checked: boolean) => {
                setCumulative(checked)
                console.log(`switch to ${checked}, cum: ${cumulative}`)
              }}
            />
          </Space>
        )}
        {showChart && (
          <KeyFigureChart
            cumulative={cumulative && cumulativesEnabled(keyFigureGroup)}
            chartType="column"
            report={reportRows.filter(
              (reportRow: ReportRow) => drawableKeyFigureIds.includes(reportRow.id) && hasData(reportRow)
            )}
            error={errors?.keyFigureStatement}
          />
        )}
        {keyFigureGroup !== 'CASH' && <KatexModal reportRows={reportRows} />}
        <KeyFigureTable
          enableCumulatives={cumulativesEnabled(keyFigureGroup)}
          report={reportRows}
          referenceValues={referenceValues}
        />
      </div>
    )
  }

  // Subscribed Keyfigure page contents
  const subscribedKeyFigurePageContent = () => {
    return subscribedKeyFigureStatement.length ? (
      <>
        <KeyFigureChart
          chartType="column"
          report={subscribedKeyFigureStatement.filter((reportRow: ReportRow) =>
            drawableKeyFigureIds.includes(reportRow.id)
          )}
          error={errors?.keyFigureStatement}
        />
        <KeyFigureTable report={subscribedKeyFigureStatement} referenceValues={subscribedKeyFigureReferenceValues} />
      </>
    ) : (
      <Result
        icon={<BarChartOutlined />}
        title={t('keyFigurePage:no-subscribtions-title')}
        subTitle={t('keyFigurePage:no-subscribtions-subtitle')}
        extra={
          <Button
            type="default"
            icon={<SettingOutlined />}
            onClick={() => navigate('/settings/company/key-figures/subscribed')}
          >
            {t('menu:/settings')}
          </Button>
        }
      />
    )
  }

  // Generate tabś
  const keyFigurePageTabItems = useMemo(() => {
    return [
      { label: t('keyFigurePage:SUBSCRIBED'), key: 'SUBSCRIBED', children: subscribedKeyFigurePageContent() },
      ...Object.entries(groupedKeyFigureStatement)
        .filter(([, reportRows]: [string, Report]) => reportRows.some(row => hasData(row)))
        .map(([keyFigureGroup, reportRows]: [string, Report]) => ({
          label: t(`keyFigurePage:${keyFigureGroup}`),
          key: keyFigureGroup,
          children: keyFigurePageContent(reportRows, keyFigureGroup)
        }))
    ]
  }, [cumulative, groupedKeyFigureStatement])

  return (
    <PageHeaderWrapper
      title={t('menu:/reporting/key-figures')}
      breadcrumb={false}
      subTitle={
        <Tooltip placement="right" title="info">
          <DocumentationLink />
        </Tooltip>
      }
      ghost={false}
    >
      <Card>
        <LoadingWrapper loading={loading.keyFigureStatement}>
          <Tabs defaultActiveKey="SUBSCRIBED" items={keyFigurePageTabItems} />
        </LoadingWrapper>
      </Card>
    </PageHeaderWrapper>
  )
}

export default KeyFigures
