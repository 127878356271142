import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import AppConfigProvider from './components/Misc/AppConfigProvider'
import { BugsnagWrapper } from './config/BugsnagWrapper'
import Loading from './pages/loading/Loading'
import { store, persistor } from './redux/store'
import App from './App'
import { AuthProvider } from './utils/hooks/useAuth'

const Root: React.FC = () => {
  return (
    <BugsnagWrapper>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loading />}>
            <AppConfigProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </AppConfigProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </BugsnagWrapper>
  )
}

export default Root
