import { createSelector } from 'reselect'
import { isArray, isNumber } from 'lodash'
import { Country, CompanyForm } from '../../types/company/Company'
import { objectMap } from '../../utils/helpers'
import { contextCompanySelector } from '../context/company/selectors'
import { Store } from '../types'
import { statementRowSelector } from '../entities/statementRows/selectors'

export const configDictionariesSelector = (store: Store) => {
  const { budgeting, cashFlowStatement, financialStatement } = store.config

  return { budgeting, cashFlowStatement, financialStatement }
}

export const normalizedConfigSelector = createSelector(
  contextCompanySelector,
  configDictionariesSelector,
  statementRowSelector,
  (company, config, statementRows) => {
    const mapped = objectMap(config, value => {
      return objectMap(value, val => {
        const data = val[company?.country ?? Country.fi][company?.form ?? CompanyForm.limited]
        if (isNumber(data)) {
          const statementRowIdsData = statementRows.filter(s => s.parentId === data)?.map(s => s.id)
          return [data, ...statementRowIdsData]
        }
        if (isArray(data)) {
          const statementRowIds = [...data].map(i => statementRows.filter(s => s.parentId === i)?.map(s => s.id)).flat()
          return [...data, ...statementRowIds]
        }
        return data
      })
    })
    return mapped
  }
)
