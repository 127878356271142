import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../utils/hooks/useAuth'

const ProtectedRoute: React.FC = () => {
  const { isLoggedIn } = useAuth()!

  if (!isLoggedIn) {
    return <Navigate to="/user/login" />
  }

  return <Outlet />
}

export default ProtectedRoute
