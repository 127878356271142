import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useBlocker } from 'react-router'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { financialStatementsPageSelector } from '../../redux/pages/budgeting/financialStatements/selectors'

const { confirm } = Modal

const useBudgetBlocker = () => {
  const { t } = useTranslation()
  const { budgetsInEdit } = useSelector(financialStatementsPageSelector)

  // Block navigating elsewhere when budget in edit mode has been entered into the input
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      Object.values(budgetsInEdit).some(b => b) && currentLocation.pathname !== nextLocation.pathname
  )

  const showConfirm = useCallback(() => {
    if (blocker.state === 'blocked') {
      confirm({
        icon: <ExclamationCircleFilled />,
        title: t('global:attention'),
        content: t('financialStatementsPage:budget-confirm-message'),
        onOk() {
          blocker.proceed()
        },
        onCancel() {
          blocker?.reset()
        }
      })
    }
  }, [blocker.state])

  useEffect(showConfirm, [showConfirm])
}

export default useBudgetBlocker
