import { DatePicker, Button, TreeSelect, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BuildOutlined, FileOutlined, FolderOutlined } from '@ant-design/icons'
import QuickFilters from './QuickFilters'
import {
  calculatedReportSelector,
  customReportDateSelector,
  customReportTreeSelector,
  customReportsSelector
} from '../../../../redux/context/customReports/selectors'
import { setCalculatedReport, setCustomReportDate } from '../../../../redux/context/customReports/actions'
import { CustomReportData } from './settings/CustomReportOrder'
import { LayoutsContext, LayoutsContextType } from './layout/LayoutContext'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { BackendRequest } from '../../../../services/backend'
import { fetchReportData } from '../service'
import { firstReport } from './utils'

interface PageHeaderExtraProps {
  getReportDataRequest: BackendRequest
  handleReportChange: (report?: CustomReport) => void
  itemsLoading?: number[]
}

const PageHeaderExtra: React.FC<PageHeaderExtraProps> = ({
  itemsLoading,
  getReportDataRequest,
  handleReportChange
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const calculatedReport = useSelector(calculatedReportSelector)
  const customReportsTree = useSelector(customReportTreeSelector)
  const date = useSelector(customReportDateSelector)
  const navigate = useNavigate()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { setLayoutEdit } = useContext(LayoutsContext) as LayoutsContextType
  const customReports = useSelector(customReportsSelector)
  const [selectDisabled, setSelectDisabled] = useState(false)

  const setDate = (newDate: string) => {
    dispatch(setCustomReportDate(newDate))
  }

  const renderTreeSelectNodes = (customReportNodes: CustomReportData[]) => {
    return customReportNodes.map(customReportNode => {
      return (
        <TreeSelect.TreeNode
          icon={customReportNode.group ? <FolderOutlined /> : <FileOutlined />}
          selectable={!customReportNode.group}
          value={customReportNode.id}
          title={customReportNode.title}
          key={customReportNode.id}
        >
          {customReportNode.children &&
            customReportNode.children.length > 0 &&
            renderTreeSelectNodes(customReportNode.children)}
        </TreeSelect.TreeNode>
      )
    })
  }

  const onReportChange = (id: number) => {
    setSelectDisabled(true)
    const report = customReports.find(r => r.id === Number(id))
    handleReportChange(report)
    setTimeout(() => {
      setSelectDisabled(false)
    }, 3000)
  }

  useEffect(() => {
    if (
      !calculatedReport?.id &&
      customReports.length > 0 &&
      customReportsTree.length > 0 &&
      !getReportDataRequest.loading &&
      !getReportDataRequest.error?.current
    ) {
      setSelectDisabled(true)
      const fetchReport = firstReport(customReportsTree) || customReports?.[0]
      fetchReportData(dispatch, getReportDataRequest, companyId, fetchReport)
      setTimeout(() => {
        setSelectDisabled(false)
      }, 3000)
    }
  }, [customReportsTree])

  return (
    <Space>
      <QuickFilters />
      <TreeSelect
        value={calculatedReport?.id}
        showSearch
        treeIcon
        disabled={selectDisabled}
        loading={itemsLoading && itemsLoading?.length > 0}
        filterTreeNode={(inputval, node) => {
          return node && (node.title as string)?.toLowerCase().includes(inputval?.toLowerCase())
        }}
        onChange={onReportChange}
        style={{ width: 300 }}
        treeLine
      >
        {customReportsTree && renderTreeSelectNodes(customReportsTree as CustomReportData[])}
      </TreeSelect>
      <Button icon={<BuildOutlined />} onClick={() => setLayoutEdit(true)} />
      <Button onClick={() => navigate('/settings/custom-report/order')}>{t('header:settings')}</Button>
      <DatePicker
        style={{ width: 100, height: '100%' }}
        key="datePicker"
        showWeek
        allowClear={false}
        defaultValue={dayjs(date)}
        onChange={newDate => newDate && setDate(newDate.toString())}
      />
    </Space>
  )
}

export default PageHeaderExtra
