import { connectRouter, RouterState } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import contextReducer from './context/reducer'
import entitiesReducer from './entities/reducer'
import errorReducer from './error/reducer'
import loadingReducer from './loading/reducer'
import sessionReducer from './session/reducer'
import threadReducer from './threads/reducer'
import pagesReducer from './pages/reducer'
import configReducer from './config/reducer'
import appReducer from './app/reducer'

export const history = createBrowserHistory()

const initialRouterState: RouterState = {
  location: {
    key: '',
    query: {},
    pathname: '/',
    search: '',
    hash: '',
    state: ''
  },
  action: 'POP'
}

const combinedReducer = combineReducers({
  app: appReducer,
  config: configReducer,
  router: connectRouter(history),
  session: sessionReducer,
  context: contextReducer,
  threads: threadReducer,
  loading: loadingReducer,
  entities: entitiesReducer,
  error: errorReducer,
  pages: pagesReducer
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('persist:root')
    // history.push('/')
    return { router: initialRouterState }
  }

  return combinedReducer(state, action)
}

export default rootReducer
