import React from 'react'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import i18nextConfig from './config/i18nextConfig'
import dayjsConfig from './config/dayjsConfig'
import './index.css'
import AppRouter from './AppRouter'

dayjsConfig()
i18nextConfig()

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

root.render(<AppRouter />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
