/* eslint-disable camelcase */
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts, { SeriesOptionsType } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomEventsOptions } from '../../../config/highcharts/types'
import { cashFlowStatementSelector } from '../../../redux/context/reports/selectors'
import { Balance, CashFlowStatementRepresentationType, Report } from '../../../redux/context/reports/types'
import { recursiveFind } from '../../../utils/helpers'
import { RequestError } from '../../../redux/error/types'
import ErrorComponent from '../../../components/Error/ErrorComponent'
import { useChartCategories, useChartSeries } from '../../../components/Chart/hooks'
import { useTableColumns } from '../../../redux/context/periodGroups/hooks'
import { setFiscalYearFilterEvent } from '../../../components/Chart/utils'
import { cashFlowStatementGraphConfigSelector } from '../../../redux/config/cashFlowStatement/selectors'
import { AppDispatch } from '../../../redux/store'

HC_exporting(Highcharts)

interface CashFlowChartProps {
  representationType: CashFlowStatementRepresentationType
  formatValue?: (balance?: Balance) => number
  selectedGraph: string
  selectedRows?: number[]
  width?: number
  height?: number
  error?: RequestError
}

const CashFlowChart: React.FC<CashFlowChartProps> = (props: CashFlowChartProps) => {
  const { representationType, selectedGraph, selectedRows, width, height, error } = props
  const categories = useChartCategories()
  const columns = useTableColumns()

  const dispatch: AppDispatch = useDispatch()

  const cashFlowGraphConfig = useSelector(cashFlowStatementGraphConfigSelector)
  const cashFlowStatement = useSelector(cashFlowStatementSelector)
  const [report, setReport] = useState<Report>([])

  // FIX ME and SEND HELP
  const configRows = useMemo(
    () =>
      selectedRows
        ? cashFlowGraphConfig?.[representationType][selectedGraph].filter(config => {
            return selectedRows.includes(config.id)
          })
        : cashFlowGraphConfig?.[representationType][selectedGraph],
    [selectedRows, selectedGraph]
  )

  const getReportRow = (config: { id: number; report: string; chartType: string; legendIndex?: number }) => {
    return recursiveFind(cashFlowStatement, config.id)
  }

  useEffect(() => {
    if (!configRows) return
    const data = configRows.map(config => {
      return getReportRow(config as any) || {}
    }) as Report
    setReport(data)
  }, [cashFlowStatement, configRows])

  const series: any[] = useChartSeries(report as Report).map((serie: any, index: any) => {
    const config = configRows && configRows.find(c => c.id === serie.rowId)
    const { chartType = 'column', legendIndex = null } = { ...config }
    return {
      ...serie,
      legendIndex,
      type: chartType,
      zIndex: chartType === 'areaspline' ? -1 : index
    }
  })

  const options: CustomEventsOptions = {
    chart: {
      width,
      height,
      animation: false
    },
    title: {
      text: ''
    },
    tooltip: {
      shared: true,
      useHTML: true,
      valueDecimals: 0,
      headerFormat: '<b>{point.key}</b><table>',
      pointFormat: `
        <tr><td><span style="color:{point.color}">◉</span> {series.name}: </td>
        <td style="text-align: right"><b>{point.y}</b></td></tr>
      `,
      footerFormat: '</table>'
    },
    xAxis: {
      categories,
      labels: {
        ...setFiscalYearFilterEvent(columns, cashFlowStatement, dispatch)
      }
    },
    yAxis: {
      title: {
        text: null
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    series: series as SeriesOptionsType[]
  }

  return error ? (
    <div style={{ padding: 16 }}>
      <ErrorComponent error={error} />
    </div>
  ) : (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </div>
  )
}

export default CashFlowChart
