import React from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useForm } from 'antd/es/form/Form'
import { styled } from 'styled-components'
import { primaryColor } from '../utils'
import { useAuth } from '../../../utils/hooks/useAuth'
import { Credentials } from '../../../redux/session/authentication/types'

const StyledLogin = styled.div`
  .login-form {
    max-width: 400px;
    padding: 0 16px;
    margin: 0 auto;
  }

  .login-input,
  .login-form button {
    height: 40px;
  }
`

const Login: React.FC = () => {
  const [form] = useForm<Credentials>()
  const { t } = useTranslation()
  const { login } = useAuth()!

  // TODO: LOGIN LOADING & ERROR

  const handleLogin = async (credentials: Credentials) => {
    try {
      login(credentials)
    } catch (e) {
      console.log('Error', e)
    }
  }

  return (
    <StyledLogin>
      <Form form={form} onFinish={handleLogin} className="login-form">
        <Form.Item name="email">
          <Input
            className="login-input email"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="email"
            placeholder={t('global:email')}
            type="email"
          />
        </Form.Item>
        <Form.Item name="password">
          <Input
            className="login-input pass"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            type="password"
            placeholder={t('global:password')}
          />
        </Form.Item>
        {/* {error && (
        <div style={{ marginBottom: 16 }}>
          <Typography.Text type="danger">{i18next.t(`description:${error.message}`, 'Error')}</Typography.Text>
        </div>
      )} */}
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ backgroundColor: primaryColor(window.location.hostname) }}
          >
            {t('global:login')}
          </Button>
        </Form.Item>
        <Link to="/user/forgot-password">{t('usersPage:forgot-password')}</Link>
      </Form>
    </StyledLogin>
  )
}

export default Login
