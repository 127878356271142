import { createSelector } from 'reselect'
import { Company } from '../../../types/company/Company'
import { ContractProduct } from '../../../types/contract/Contract'
import { Store } from '../../types'
import { contextContractProductSelector } from '../../context/contract/selectors'

const currentUser = (state: Store) => state.session && state.session.currentUser

export const userCompaniesSelector = createSelector(currentUser, user => user && user.companies)

export const currentUserSelector = createSelector(currentUser, user => user)
export const currentUserIdSelector = createSelector(currentUser, user => user && user.id)
export const currentUserRoleSelector = createSelector(currentUser, user => user && user.role)
export const currentUserIsAdminSelector = createSelector(currentUserRoleSelector, role => {
  return role.label === 'superuser' || role.label.includes('admin')
})
export const currentUserPermissionSelector = createSelector(currentUser, user => user && user.permissions)
export const currentUserDimensionSelector = createSelector(currentUser, user => user && user.dimensions)
export const currentUserContractIdSelector = createSelector(currentUser, user => user && user.contractId)
export const currentUserTourCompletedSelector = createSelector(currentUser, user => user && user.tourCompleted)
export const currentUserTermsConditionsSelector = createSelector(
  currentUser,
  user => user && user.termsAndConditionsAgreed
)

export const currentUserHomepageSelector = createSelector(
  [currentUser, contextContractProductSelector],
  (user, product) => {
    const { settings } = user || {}
    const homepage = settings?.find(s => s.settingId === 'homepage')

    if (homepage?.value) {
      return `/${homepage.value}`
    }

    return product === ContractProduct.LIGHT ? '/reporting/financial-statements' : '/budgeting/financial-statements'
  }
)

export const currentUserSortedCompaniesSelector = createSelector(userCompaniesSelector, companies =>
  (companies || []).slice().sort((a: Company, b: Company) => {
    const nameA = a.name.toUpperCase() // ignore upper and lowercase
    const nameB = b.name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
)

export const currentUserCompaniesArgumentOptionsSelector = createSelector(userCompaniesSelector, companies => {
  return companies?.map(company => ({
    name: `${company.id} - ${company.name}`,
    value: `'${company.id}'`
  }))
})
