import { ConfigDictionary, SetAppConfigsAction } from '../types'

export interface CashFlowStatementGraphConfig {
  [key: string]: {
    [key: string]: { id: number; chartType: string; legendIndex?: number }[]
  }
}

export interface CashFlowStatementConfig {
  cashFlowGraphConfig: ConfigDictionary<CashFlowStatementGraphConfig>
}

export const SET_CASH_FLOW_STATEMENT_CONFIG = 'SET_CASH_FLOW_STATEMENT_CONFIG'

export interface SetCashFlowStatementConfigAction {
  type: typeof SET_CASH_FLOW_STATEMENT_CONFIG
  payload: CashFlowStatementConfig
}

export type CashFlowStatementConfigActionTypes = SetCashFlowStatementConfigAction | SetAppConfigsAction
