// eslint-disable-next-line max-classes-per-file
import React, { createContext, useContext, useMemo } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { currentUserHomepageSelector } from '../../redux/session/currentUser/selectors'
import { Credentials } from '../../redux/session/authentication/types'
import { setAppConfigs } from '../../redux/config/actions'
import { setEntities } from '../../redux/entities/actions'
import { logoutAction, setToken } from '../../redux/session/authentication/actions'
import { setCurrentUser } from '../../redux/session/currentUser/actions'
import { getCurrentUser, fetchConfigs, fetchEntities } from '../../routes/loginRequest'
import { AppDispatch } from '../../redux/store'
import { useLogin } from '../../redux/session/currentUser/hooks'

type AuthContextType = {
  isLoggedIn: boolean
  login: (credentials: Credentials) => Promise<void>
  logout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch: AppDispatch = useDispatch()
  const homepage = useSelector(currentUserHomepageSelector)
  const isLoggedIn = useLogin()
  const navigate = useNavigate()

  // TODO: Check in Request refactor
  // call this function when you want to authenticate the user
  const login = async (credentials: Credentials) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, credentials)
    dispatch(setToken(data))

    const currentUser = await getCurrentUser()
    dispatch(setCurrentUser(currentUser))

    const config = await fetchConfigs()
    dispatch(setAppConfigs(config))

    const entities = await fetchEntities()
    dispatch(setEntities(entities))

    navigate(homepage)
  }

  // call this function to sign out logged in user
  const logout = () => {
    // TODO: Check in Request refactor
    dispatch(logoutAction())
    navigate('/', { replace: true })
  }

  const value = useMemo(
    () => ({
      isLoggedIn,
      login,
      logout
    }),
    [isLoggedIn]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
