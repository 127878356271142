import React, { useState } from 'react'
import { Checkbox, Col, DatePicker, Form, Row, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { RuleRender } from 'antd/lib/form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useWatch } from 'antd/es/form/Form'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import { longTermLiabilityRowIdsSelector } from '../../../../redux/config/financialStatement/selectors'
import { loanRepaymentMethodsSelector } from '../../../../redux/entities/loanRepaymentMethods/selectors'
import { LoanRepaymentMethod } from '../../../../redux/entities/loanRepaymentMethods/types'
import { getFormName } from '../../financialStatements/components/investment/utils'
import DimensionSelect from '../../../../components/Dimension/DimensionSelect'
import AccountSelect from '../../../../components/inputs/AccountSelect'
import { liabilityMappingSelector } from '../../../../redux/config/liabilityMapping/selectors'
import { normalizedConfigSelector } from '../../../../redux/config/selectors'

interface LoanFormFieldsProps {
  loanType: 'existing' | 'new' | 'investment'
  fieldDecoratorPrefix?: (string | number)[]
  purposes?: string[]
  addNew?: boolean
}

const defaultProps = {
  fieldDecoratorPrefix: ''
}

const infoTooltip = (text: string) => (
  <Tooltip placement="top" title={text}>
    <InfoCircleOutlined style={{ verticalAlign: 'middle', marginLeft: 8 }} />
  </Tooltip>
)

export const LoanFormFields = ({ fieldDecoratorPrefix, loanType, purposes, addNew }: LoanFormFieldsProps) => {
  const { t } = useTranslation()
  const form = useFormInstance()
  const balanceSheetRowId = useWatch(
    fieldDecoratorPrefix ? ['fundings', ...fieldDecoratorPrefix, 'balanceSheetRowId'] : 'balanceSheetRowId',
    form
  )
  const [validationPassed, setValidationPassed] = useState(true)
  const loanRepaymentMethods = useSelector(loanRepaymentMethodsSelector)
  const {
    financialStatement: { financialExpenseRowId }
  } = useSelector(normalizedConfigSelector)
  const liabilityMapping = useSelector(liabilityMappingSelector)
  const longTermLiabilityRowIds: any = useSelector(longTermLiabilityRowIdsSelector)

  const loanValidation: RuleRender = ({ getFieldValue, setFieldsValue, setFields }) => ({
    validator: async (__: any) => {
      const field = __.field.split('.')
      const preField = field.slice(0, -1)
      const termYears = getFieldValue(preField.concat('termYears'))
      const termMonths = getFieldValue(preField.concat('termMonths'))
      if (getFieldValue(preField.concat('termsAndRepaymentsSame'))) {
        if (fieldDecoratorPrefix) {
          setFields([
            {
              name: preField.concat('repaymentProgramYears'),
              value: termYears
            },
            {
              name: preField.concat('repaymentProgramMonths'),
              value: termMonths
            }
          ])
        } else {
          setFieldsValue({
            repaymentProgramYears: termYears,
            repaymentProgramMonths: termMonths
          })
        }
      }
      const repaymentProgramYears = getFieldValue(preField.concat('repaymentProgramYears'))
      const repaymentProgramMonths = getFieldValue(preField.concat('repaymentProgramMonths'))

      const isValid = termYears * 12 + termMonths >= repaymentProgramYears * 12 + repaymentProgramMonths
      if (
        Number.isFinite(termYears) &&
        Number.isFinite(termMonths) &&
        Number.isFinite(repaymentProgramYears) &&
        Number.isFinite(repaymentProgramMonths)
      ) {
        setValidationPassed(isValid)
        if (!isValid) {
          return Promise.reject(new Error(''))
        }
      }
      return Promise.resolve()
    }
  })

  const hiddenFields = (getFieldValue: any) => {
    if (fieldDecoratorPrefix) {
      return getFieldValue(['fundings', fieldDecoratorPrefix[0], 'loan', 'termsAndRepaymentsSame']) === true
    }
    return getFieldValue(getFormName('termsAndRepaymentsSame', fieldDecoratorPrefix)) === true
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t('loans:balanceSheetRow')}
            name={getFormName('balanceSheetRowId', fieldDecoratorPrefix)}
            rules={[{ required: true, message: ' ' }]}
            hidden={!addNew}
          >
            <Select showSearch optionFilterProp="children">
              {(longTermLiabilityRowIds || []).map((rowId: any) => {
                return (
                  <Select.Option key={rowId} value={rowId}>
                    {t(`balanceSheet:${rowId}`)}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        {addNew ? (
          <Col span={12}>
            <Form.Item
              label={t('loans:remainingLoanValue')}
              name={getFormName('value', fieldDecoratorPrefix)}
              rules={[{ required: true, message: '' }]}
            >
              <FormattedInputNumber block min={0} />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Col span={24}>
        <Row gutter={16} align="bottom">
          <Col span={12}>
            <Form.Item
              label={t('loans:financialExpensesAccountCode')}
              name={getFormName('financialExpensesAccountCode', fieldDecoratorPrefix)}
              rules={[{ required: true, message: '' }]}
            >
              <AccountSelect balanceSheetRowIds={financialExpenseRowId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('loans:longTermAccountCode')}
              name={getFormName('longTermAccountCode', fieldDecoratorPrefix)}
              rules={[{ required: true, message: '' }]}
            >
              <AccountSelect balanceSheetRowIds={[balanceSheetRowId]} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('loans:shortTermAccountCode')}
              name={getFormName('shortTermAccountCode', fieldDecoratorPrefix)}
              rules={[{ required: true, message: '' }]}
            >
              <AccountSelect balanceSheetRowIds={[liabilityMapping?.[balanceSheetRowId]]} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Form.Item
          name={getFormName('nextInstalmentDate', fieldDecoratorPrefix)}
          rules={[{ required: true, message: '' }]}
          label={loanType === 'existing' ? t('loans:next-instalment-date') : t('loans:loan-drawdown-date')}
        >
          <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            initialValue={0}
            validateStatus={!validationPassed ? 'error' : 'success'}
            help={!validationPassed ? t('loans:loanFieldTerms') : null}
            name={getFormName('termYears', fieldDecoratorPrefix)}
            rules={[{ required: true, message: '' }, loanValidation]}
            label={
              <>
                {t('loans:term-year')}
                {infoTooltip(t('loans:term/info'))}
              </>
            }
          >
            <FormattedInputNumber block min={0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            initialValue={0}
            validateStatus={!validationPassed ? 'error' : 'success'}
            help={!validationPassed ? t('loans:loanFieldTerms') : null}
            rules={[{ required: true, message: '' }, loanValidation]}
            shouldUpdate
            name={getFormName('termMonths', fieldDecoratorPrefix)}
            label={t('loans:term-month')}
          >
            <FormattedInputNumber block min={0} max={12} />
          </Form.Item>
        </Col>
      </Row>
      <Col span={12}>
        <Form.Item
          initialValue
          valuePropName="checked"
          name={getFormName('termsAndRepaymentsSame', fieldDecoratorPrefix)}
          rules={[loanValidation]}
          label={t('loans:termsAndRepaymentsSame')}
        >
          <Checkbox />
        </Form.Item>
      </Col>
      <Row gutter={16}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <Col span={12}>
                <Form.Item
                  hidden={hiddenFields(getFieldValue)}
                  validateStatus={!validationPassed ? 'error' : 'success'}
                  help={!validationPassed ? t('loans:loanFieldTerms') : null}
                  rules={[{ required: true, message: '' }, loanValidation]}
                  name={getFormName('repaymentProgramYears', fieldDecoratorPrefix)}
                  label={
                    <>
                      {t('loans:repayment-program-years')}
                      {infoTooltip(t('loans:repayment-program/info'))}
                    </>
                  }
                >
                  <FormattedInputNumber block min={0} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  hidden={hiddenFields(getFieldValue)}
                  validateStatus={!validationPassed ? 'error' : 'success'}
                  help={!validationPassed ? t('loans:loanFieldTerms') : null}
                  rules={[{ required: true, message: '' }, loanValidation]}
                  name={getFormName('repaymentProgramMonths', fieldDecoratorPrefix)}
                  label={t('loans:repayment-program-moths')}
                >
                  <FormattedInputNumber block min={0} max={12} />
                </Form.Item>
              </Col>
            </>
          )}
        </Form.Item>
      </Row>
      <Row gutter={16}>
        <Form.Item noStyle shouldUpdate>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: '' }]}
              initialValue={0}
              name={getFormName('instalmentFreePeriod', fieldDecoratorPrefix)}
              label={
                <>
                  {t('loans:instalment-free-period')}
                  {infoTooltip(t('loans:instalment-free-period/info'))}
                </>
              }
            >
              <FormattedInputNumber block min={0} />
            </Form.Item>
          </Col>
        </Form.Item>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true, message: '' }]}
            name={getFormName('loanRepaymentMethodId', fieldDecoratorPrefix)}
            label={
              <>
                {t('loans:loan-repayment-method')}
                {infoTooltip(t('loans:loan-repayment-method/info'))}
              </>
            }
          >
            <Select showSearch optionFilterProp="children">
              {loanRepaymentMethods.map((loanRepaymentMethod: LoanRepaymentMethod) => {
                return (
                  <Select.Option key={loanRepaymentMethod.id} value={loanRepaymentMethod.id}>
                    {t(`loanRepaymentMethod:${loanRepaymentMethod.id}`)}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            initialValue={12}
            rules={[{ required: true, message: '' }]}
            name={getFormName('yearlyInstalments', fieldDecoratorPrefix)}
            label={t('loans:yearly-instalments')}
          >
            <FormattedInputNumber block min={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            initialValue={0}
            rules={[{ required: true, message: '' }]}
            name={getFormName('interestRate', fieldDecoratorPrefix)}
            label={t('loans:interest-rate')}
          >
            <FormattedInputNumber block percentage addonAfter="%" min={0} max={100} step={1} />
          </Form.Item>
        </Col>
      </Row>
      {loanType === 'existing' && (
        <Col span={12}>
          <Form.Item label={t('loans:purposes')} name={getFormName('purposes', fieldDecoratorPrefix)}>
            <Select style={{ width: '100%' }} mode="tags" showSearch optionFilterProp="children">
              {purposes &&
                purposes.map(item => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col span={12}>
        <Form.Item label={t('global:dimension')} name={getFormName('dimensionId', fieldDecoratorPrefix)}>
          <DimensionSelect />
        </Form.Item>
      </Col>
    </>
  )
}

LoanFormFields.defaultProps = defaultProps
