import { Country, CompanyForm } from '../../types/company/Company'
import { BudgetingConfig } from './budgeting/types'
import { CashFlowStatementConfig } from './cashFlowStatement/types'
import { FinancialStatementConfig } from './financialStatement/types'
import { LiabilityMapping } from './liabilityMapping/types'

export const SET_APP_CONFIGS = 'SET_APP_CONFIGS'

export interface Config {
  cashFlowStatement: CashFlowStatementConfig
  financialStatement: FinancialStatementConfig
  budgeting: BudgetingConfig
  liabilityMapping: LiabilityMapping
}

export interface ConfigDictionary<T> {
  [Country.fi]: { [key in CompanyForm]: T }
  [Country.se]: { [key in CompanyForm]: T }
}

export interface CashAppConfigs {
  cashFlowGraphConfig: Config
}

export interface SetAppConfigsAction {
  type: typeof SET_APP_CONFIGS
  payload: Config
}
