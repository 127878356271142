import React, { useEffect, useRef } from 'react'
import hljs from 'highlight.js/lib/core'
import erlang from 'highlight.js/lib/languages/erlang'
// Then register the languages you need
hljs.registerLanguage('erlang', erlang)

interface CodeBlockProps {
  code: string
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code }) => {
  const codeRef = useRef<HTMLElement>(null)
  useEffect(() => {
    if (codeRef && codeRef.current) {
      hljs.highlightElement(codeRef.current)
    }
  }, [code])

  return (
    <pre>
      <code className="language-erlang" ref={codeRef}>
        {code}
      </code>
    </pre>
  )
}

export default CodeBlock
