import i18next from 'i18next'
import React, { useState } from 'react'
import fiLocale from 'antd/lib/locale/fi_FI'
import enLocale from 'antd/lib/locale/en_US'
import svLocale from 'antd/lib/locale/sv_SE'
import { ConfigProvider, Spin, theme } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Locale } from 'antd/es/locale'
import { Language } from '../../types/user/User'
import { sessionLayoutSelector } from '../../redux/session/contract/selectors'
import { customerThemes } from '../../config/customerThemes'

const getLocale = (lang: Language) => {
  switch (lang) {
    case Language.en:
      return enLocale
    case Language.sv:
      return svLocale
    case Language.fi:
    default:
      return fiLocale
  }
}

Spin.setDefaultIndicator(<LoadingOutlined />)

const AppConfigProvider: React.FC = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(getLocale(i18next.language as Language))
  const whiteLabel = useSelector(sessionLayoutSelector)
  const token = customerThemes[whiteLabel]?.token || customerThemes.app.token

  i18next.on('languageChanged', (lng: Language) => setLocale(getLocale(lng)))

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Manrope',
          fontSize: 14,

          colorText: 'rgba(0, 0, 0, 0.98)',
          ...token
        },
        algorithm: theme.compactAlgorithm,
        components: {
          Dropdown: {
            zIndexPopup: 11000
          },
          Table: {
            lineHeight: 1.2
          },
          Card: {
            padding: 4,
            paddingLG: 8
          },
          Badge: {
            fontSizeSM: 20
          }
        }
      }}
      locale={locale}
    >
      {children}
    </ConfigProvider>
  )
}

export default AppConfigProvider
