import { SET_APP_CONFIGS } from '../types'
import { CashFlowStatementConfigActionTypes, SET_CASH_FLOW_STATEMENT_CONFIG } from './types'

const initialState = {}

const cashFlowStatementConfigReducer = (state = initialState, action: CashFlowStatementConfigActionTypes) => {
  switch (action.type) {
    case SET_CASH_FLOW_STATEMENT_CONFIG:
      return action.payload
    case SET_APP_CONFIGS:
      return action.payload.cashFlowStatement
    default:
      return state
  }
}

export default cashFlowStatementConfigReducer
