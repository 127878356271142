import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  BarChartOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { Button, Result, Skeleton, Popover } from 'antd'
import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReportTableRow } from '../../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { periodGroupLabel } from '../../../../redux/context/fiscalYears/utils'
import { getPerformanceIndicatorsRequest } from '../../../../redux/context/performanceIndicators/actions'
import { keyPerformanceIndicatorsSelector as keyPerformanceIndicatorsSettinsSelector } from '../../../../redux/context/performanceIndicators/selectors'
import { getKeyPerformanceIndicatorStatmentRequest } from '../../../../redux/context/reports/actions'
import {
  keyPerformanceIndicatorStatementLoadingSelector,
  keyPerformanceIndicatorStatementSelector
} from '../../../../redux/context/reports/selectors'
import { keyFigureReferenceColors } from '../../../reporting/keyFigures/components/referenceValueTable/config'
import KeyPerformanceIndicatorChart from './components/KeyPerformanceIndicatorChart'
import { formatValueByType } from '../../../../utils/helpers'
import { contextRequest } from '../../../../redux/context/actions'
import { ReportDataType } from '../../../../redux/context/reports/types'
import ReportTable from '../../../../components/Table/ReportTable'
import { Column } from '../../../../redux/context/periodGroups/hooks'
import { sortedColumnGroupSelector } from '../../../../redux/context/periodGroups/selectors'
import { PeriodGroup } from '../../../../types/periodGroup/PeriodGroup'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'
import {
  getPreviousPeriodDataIndex,
  getPreviousPeriodGroup,
  getPreviousPeriodGroupDataIndex
} from '../../../../redux/context/periodGroups/utils'
import { dimensionQuerySelector, filtersSelector } from '../../../../redux/context/filters/selectors'
import { AppDispatch } from '../../../../redux/store'
import { loadingSelector } from '../../../../redux/loading/selectors'
import { appLanguageSelector } from '../../../../redux/app/selectors'

const KeyPerformanceIndicators: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const { budgetingScenario, dimensions, periodGroups } = useSelector(filtersSelector)
  const loading = useSelector(keyPerformanceIndicatorStatementLoadingSelector)
  const keyPerformanceIndicators = useSelector(keyPerformanceIndicatorsSettinsSelector)
  const keyPerformanceIndicatorStatement = useSelector(keyPerformanceIndicatorStatementSelector) || []
  const sortedGroups = useSelector(sortedColumnGroupSelector)
  const { dimensions: dimensionsLoading } = useSelector(loadingSelector)
  const dimensionQuery = useSelector(dimensionQuerySelector)
  const language = useSelector(appLanguageSelector)

  useEffect(() => {
    if ((companyId && dimensions?.length && !dimensionsLoading) || (companyId && !dimensions?.length)) {
      dispatch(contextRequest(getKeyPerformanceIndicatorStatmentRequest))
    }
  }, [companyId, budgetingScenario, dimensionQuery, periodGroups, language])

  useEffect(() => {
    if (companyId) {
      dispatch(contextRequest(getPerformanceIndicatorsRequest))
    }
  }, [companyId, language])

  const getCompareDataLabel = (dataIndex: string, periodGroup?: PeriodGroup | FiscalYear) => {
    const [dataType, year, month] = dataIndex.split('-')

    let label: string | undefined = ''
    if (month) {
      label = `${month} / ${year}`
    } else {
      label = periodGroup && periodGroupLabel(periodGroup, dataType as ReportDataType)
    }

    if (dataType === ReportDataType.budget) {
      label += ' budget'
    }
    if (dataType === ReportDataType.forecast) {
      label += ' forecast'
    }

    return label
  }

  const getIcon = (current: number, previous?: number) => {
    if (!previous) return <ArrowRightOutlined />
    if (current > previous) {
      return <ArrowUpOutlined style={{ color: keyFigureReferenceColors.excellent }} />
    }
    if (current < previous) {
      return <ArrowDownOutlined style={{ color: keyFigureReferenceColors.passable }} />
    }

    return <ArrowRightOutlined />
  }

  const renderCell = (text: any, reportTableRow: ReportTableRow, column: Column) => {
    if (!text) return undefined
    const value = formatValueByType(text, reportTableRow.variableType)
    let compareDataIndex: string | 0 | undefined = ''
    if (column.periodGroup) {
      compareDataIndex = getPreviousPeriodGroupDataIndex(column, sortedGroups)
    } else {
      compareDataIndex = getPreviousPeriodDataIndex(column)
    }

    const compareValue =
      compareDataIndex &&
      reportTableRow[compareDataIndex] &&
      formatValueByType(reportTableRow[compareDataIndex], reportTableRow.variableType)
    const compareLabel =
      compareDataIndex &&
      getCompareDataLabel(compareDataIndex, getPreviousPeriodGroup(sortedGroups, column.periodGroup?.id))

    return compareValue && compareDataIndex ? (
      <Popover title={compareLabel} content={compareValue}>
        {value}
        &nbsp;
        {getIcon(text, reportTableRow[compareDataIndex])}
      </Popover>
    ) : (
      <>{value}</>
    )
  }

  const renderTitle = (reportTableRow: ReportTableRow): ReactNode => {
    return (
      <>
        <div>{t(`${reportTableRow.title}`)}</div>
        <div style={{ position: 'absolute', top: 4, right: 0 }}>
          <KeyPerformanceIndicatorChart reportTableRow={reportTableRow} />
        </div>
      </>
    )
  }

  return (
    <Skeleton title={false} loading={loading} active paragraph={{ rows: 5 }}>
      {keyPerformanceIndicators.length ? (
        <ReportTable
          toolbar={{ csvExport: false }}
          report={keyPerformanceIndicatorStatement}
          renderCell={renderCell}
          renderTitle={renderTitle}
        />
      ) : (
        <Result
          style={{ padding: 0 }}
          icon={<BarChartOutlined style={{ display: 'none' }} />}
          title={t('keyFigurePage:no-kpis-title')}
          subTitle={t('keyFigurePage:no-kpis-subtitle')}
          extra={
            <Button
              type="default"
              icon={<SettingOutlined />}
              onClick={() => navigate('/settings/company/key-figures/key-performance-indicators')}
            >
              {t('menu:/settings')}
            </Button>
          }
        />
      )}
    </Skeleton>
  )
}

export default KeyPerformanceIndicators
