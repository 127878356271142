import { Cascader } from 'antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import routeConfig from '../../../../../routes/routeConfig'
import { useAuthorizedData } from '../../../../../utils/Authorizable/authorize'
import { filterRoute } from '../../../../../utils/helpers'
import { Route } from '../../../../../routes/Route'

interface RouteCascaderProps {
  id?: string
  value?: string[]
  onChange?: (value: string[]) => void
}

const RouteCascader: React.FC<RouteCascaderProps> = ({ id, value = [], onChange }) => {
  const { t } = useTranslation()
  const authorizedRoutes = useAuthorizedData(routeConfig)

  // const authorizedRoutes = useAuthorizedRoutes()
  const route = useMemo(
    () =>
      filterRoute(authorizedRoutes[0], r => {
        return !!r.path && !r.hideInMenu
      }),
    [authorizedRoutes]
  )

  const map = ({ routes: r, name, path }: Route): Route => ({
    path: name,
    name: t(`menu:${path}`),
    routes: r?.map(map) // recursive call
  })

  return (
    <span id={id}>
      <Cascader
        value={value}
        onChange={onChange}
        style={{ width: 400 }}
        fieldNames={{ label: 'name', value: 'path', children: 'routes' }}
        options={route?.routes?.map(map)}
      />
    </span>
  )
}

export default RouteCascader
