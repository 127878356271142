import dayjs, { Dayjs } from 'dayjs'
import { DownOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Select, DatePicker, Space, Popover, Flex, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'antd/es/form/Form'
import { useBackend } from '../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { emptyQuickFiltersData, setQuickFiltersData } from '../../../../redux/context/customReports/actions'
import { customReportQuickFiltersSelector } from '../../../../redux/context/customReports/selectors'
import { AppDispatch } from '../../../../redux/store'

const { RangePicker } = DatePicker

export interface QuickFilterForm {
  period?: {
    start: string
    end: string
  }
  sales?: {
    suppliers?: (number | string)[]
    sellers?: (number | string)[]
    dimensions?: (number | string)[]
  }
}

export interface QuickFilterFormData {
  period?: [Dayjs, Dayjs]
  sales?: {
    suppliers?: (number | string)[]
    sellers?: (number | string)[]
    dimensions?: (number | string)[]
  }
}

export interface QuickFilterData {
  period?: {
    start: string
    end: string
  }
  sales?: {
    suppliers?: { id: string | number; name: string }[]
    sellers?: { id: string | number; name: string }[]
    dimensions?: { id: string | number; name: string }[]
  }
}

const QuickFilters: React.FC = () => {
  const [form] = useForm()
  const dispatch: AppDispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const savedQuickFilters = useSelector(customReportQuickFiltersSelector)
  const [quickFilters, setQuickFilters] = useState<QuickFilterData>({})
  const companyId = useSelector(contextCompanyIdSelector)
  const quickFiltersRequest = useBackend('/api/companies/{companyId}/reporting/custom/quick-filter')

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const getFilters = async () => {
    try {
      const response = await quickFiltersRequest.get({
        urlParams: { companyId }
      })
      setQuickFilters(response)
    } catch (error) {
      setQuickFilters({})
    }
  }

  const formatData = (data: QuickFilterFormData): QuickFilterForm => {
    const { period = [], sales } = data
    const [start, end] = period

    const format = 'YYYY-MM'
    return {
      period:
        end && start
          ? {
              start: start?.format(format),
              end: end?.format(format)
            }
          : undefined,
      sales
    }
  }

  const formatFilters = (data?: QuickFilterForm): QuickFilterFormData | undefined => {
    if (!data) return undefined
    const { period, sales } = data
    const { start, end } = period || {}

    return {
      period: end && start ? [dayjs(start), dayjs(end)] : undefined,
      sales
    }
  }

  const handleSubmit = (data: QuickFilterFormData) => {
    const transformed = formatData(data)
    dispatch(setQuickFiltersData(transformed))
    setOpen(false)
  }

  const handleClear = () => {
    form.resetFields()
    dispatch(emptyQuickFiltersData())
    setOpen(false)
  }

  const handleCancel = () => {
    form.resetFields()
    setOpen(false)
  }

  const selectAll = (name: 'suppliers' | 'sellers' | 'dimensions') => {
    const value = quickFilters?.sales?.[name]?.map(({ id }) => id)
    form.setFieldValue(['sales', name], value)
  }

  useEffect(() => {
    if (companyId) {
      getFilters()
    }
  }, [companyId])

  useEffect(() => {
    if (open) {
      form.setFieldsValue(formatFilters(savedQuickFilters))
    }
  }, [open])

  if (Object.keys(quickFilters).length === 0) {
    return null
  }

  const content = (
    <Form form={form} onFinish={handleSubmit}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <Form.Item name="period" noStyle>
          <RangePicker
            size="middle"
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
            style={{ width: '100%' }}
            placeholder={[t('contextDrawer:start'), t('contextDrawer:end')]}
            format="YYYY-MM"
            picker="month"
            allowClear
          />
        </Form.Item>
        <Flex gap={4}>
          <Button onClick={() => selectAll('sellers')}>Valitse kaikki</Button>
          <Form.Item name={['sales', 'sellers']} noStyle>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              mode="multiple"
              allowClear
              showSearch
              maxTagCount="responsive"
              placeholder="Myyjä(t)"
              style={{ width: '100%' }}
              fieldNames={{ label: 'name', value: 'id' }}
              options={quickFilters?.sales?.sellers || []}
            />
          </Form.Item>
        </Flex>
        <Flex gap={4}>
          <Button onClick={() => selectAll('suppliers')}>Valitse kaikki</Button>
          <Form.Item name={['sales', 'suppliers']} noStyle>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              mode="multiple"
              allowClear
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              placeholder="Toimittaja(t)"
              style={{ width: '100%' }}
              fieldNames={{ label: 'name', value: 'id' }}
              options={quickFilters?.sales?.suppliers || []}
            />
          </Form.Item>
        </Flex>
        <Flex gap={4}>
          <Button onClick={() => selectAll('dimensions')}>Valitse kaikki</Button>
          <Form.Item name={['sales', 'dimensions']} noStyle>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              mode="multiple"
              allowClear
              showSearch
              maxTagCount="responsive"
              placeholder="Laskentakohde"
              style={{ width: '100%' }}
              fieldNames={{ label: 'name', value: 'id' }}
              options={quickFilters?.sales?.dimensions || []}
            />
          </Form.Item>
        </Flex>
        <Space>
          <Button danger onClick={handleClear}>
            Tyhjennä
          </Button>
          <Button onClick={handleCancel}>Peruuta</Button>
          <Button type="primary" htmlType="submit">
            Tallenna valinnat
          </Button>
        </Space>
      </Space>
    </Form>
  )

  return (
    <Space>
      <Popover
        overlayStyle={{ width: 400 }}
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button icon={<FilterOutlined />}>
          Pikafiltterit <DownOutlined />
        </Button>
      </Popover>
    </Space>
  )
}

export default QuickFilters
