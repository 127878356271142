import { combineReducers } from 'redux'
import BudgetingConfigReducer from './budgeting/reducer'
import cashFlowStatementConfigReducer from './cashFlowStatement/reducer'
import financialStatementConfigReducer from './financialStatement/reducer'
import LiabilityMappingReducer from './liabilityMapping/reducer'

const configReducer = combineReducers({
  cashFlowStatement: cashFlowStatementConfigReducer,
  financialStatement: financialStatementConfigReducer,
  budgeting: BudgetingConfigReducer,
  liabilityMapping: LiabilityMappingReducer
})

export default configReducer
