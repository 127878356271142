import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dictionary } from 'lodash'
import { store } from '../redux/store'
import { LiabilityMapping } from '../redux/config/liabilityMapping/types'
import { FinancialStatementConfig } from '../redux/config/financialStatement/types'
import { CashFlowStatementConfig } from '../redux/config/cashFlowStatement/types'
import { BudgetingConfig } from '../redux/config/budgeting/types'
import { Config } from '../redux/config/types'
import { User } from '../types/user/User'
import { Permission } from '../types/user/Permission'
import { StatementRow } from '../types/statementRow/StatementRow'
import { Entities } from '../redux/entities/types'
import { LoanRepaymentMethod } from '../redux/entities/loanRepaymentMethods/types'
import { KeyFigure } from '../types/keyFigure/KeyFigure'
import { InvestmentFundingSource } from '../types/investment/InvestmentFundingSource'
import { Industry } from '../types/industry/Industry'
import { ContractProduct } from '../types/contract/Contract'

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
})

const request = async <T>(options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse<T>) => {
    const { data } = response
    return data
  }

  const onError = (error: AxiosError) => {
    return Promise.reject(error.response?.data)
  }

  return client(options).then(onSuccess).catch(onError)
}

// Add a request interceptor
client.interceptors.request.use(
  config => {
    const token = (store.getState() as any)?.session?.authentication?.token
    // Do something before request is sent
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Token': token
      }
    }
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Configs
const getBudgetingConfig = async () => {
  return request<BudgetingConfig>({
    method: 'GET',
    url: '/api/config/budgeting'
  })
}

const getCashFlowStatementConfig = async () => {
  return request<CashFlowStatementConfig>({
    method: 'GET',
    url: '/api/config/cash-flow-statement'
  })
}

const getFinancialStatementConfig = async () => {
  return request<FinancialStatementConfig>({
    method: 'GET',
    url: '/api/config/financial-statement'
  })
}

const getLiabilityMappingConfig = async () => {
  return request<LiabilityMapping>({
    method: 'GET',
    url: '/api/config/liability-mapping'
  })
}

// Current user
export const getCurrentUser = async () => {
  return request<User>({
    method: 'GET',
    url: '/api/users/current'
  })
}

// Entities

const getContractProducts = async () => {
  return request<Dictionary<ContractProduct>>({
    method: 'GET',
    url: '/api/contract-products'
  })
}

const getIndustries = async () => {
  return request<Industry[]>({
    method: 'GET',
    url: '/api/industries'
  })
}

const getInvestmentFundingSources = async () => {
  return request<InvestmentFundingSource[]>({
    method: 'GET',
    url: '/api/investment-funding-sources'
  })
}

const getKeyFigures = async () => {
  return request<KeyFigure[]>({
    method: 'GET',
    url: '/api/key-figures/'
  })
}

const getLoanRepaymentMethods = async () => {
  return request<LoanRepaymentMethod[]>({
    method: 'GET',
    url: '/api/loan-repayment-methods'
  })
}

const getPermissions = async () => {
  return request<Permission[]>({
    method: 'GET',
    url: '/api/permissions'
  })
}

const getStatementRows = async () => {
  return request<StatementRow[]>({
    method: 'GET',
    url: '/api/statement-rows'
  })
}

export async function fetchConfigs() {
  try {
    const [budgeting, cashFlowStatement, financialStatement, liabilityMapping] = await Promise.all([
      getBudgetingConfig(),
      getCashFlowStatementConfig(),
      getFinancialStatementConfig(),
      getLiabilityMappingConfig()
    ])

    // Return an object with the results
    return {
      budgeting,
      cashFlowStatement,
      financialStatement,
      liabilityMapping
    } as Config
  } catch (error) {
    // Handle any error from the requests
    console.error('Error occurred while fetching configs:', error)
    throw error
  }
}

export async function fetchEntities() {
  try {
    const [
      contractProducts,
      industries,
      investmentFundingSources,
      keyFigures,
      loanRepaymentMethods,
      permissions,
      statementRows
    ] = await Promise.all([
      getContractProducts(),
      getIndustries(),
      getInvestmentFundingSources(),
      getKeyFigures(),
      getLoanRepaymentMethods(),
      getPermissions(),
      getStatementRows()
    ])

    // Return an object with the results
    return {
      contractProducts,
      industries,
      investmentFundingSources,
      keyFigures,
      loanRepaymentMethods,
      permissions,
      statementRows
    } as Entities
  } catch (error) {
    // Handle any error from the requests
    console.error('Error occurred while fetching entities:', error)
    throw error
  }
}
