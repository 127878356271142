import { EditOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import dayjs from 'dayjs'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ColumnGroupType, ColumnType } from 'antd/es/table'
import { contractProductsSelector } from '../../../redux/entities/contractProducts/selectors'
import { sessionContractChildrenSelector } from '../../../redux/session/contract/selectors'
import { Contract } from '../../../types/contract/Contract'
import { useAuthorizedData } from '../../../utils/Authorizable/authorize'
import { useTableProps } from '../../../utils/hooks'
import { useColumnSearchProps } from '../../Table/useColumSearchProps'
import UpdateContractFormModal from './UpdateContractForm'

const ContractTable: React.FC = () => {
  const { t } = useTranslation()
  const children = useSelector(sessionContractChildrenSelector)
  const products = useSelector(contractProductsSelector)
  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl)
  const [contractInEdit, setContractInEdit] = useState<Contract | null>(null)
  const nameSearchProps = useColumnSearchProps('name')
  const columns = useMemo(
    () =>
      [
        {
          title: t('contractsPage:name'),
          key: 'name',
          dataIndex: 'name' as string,
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
          sortDirections: ['descend', 'ascend'],
          ...nameSearchProps
        },
        {
          title: t('contractsPage:product'),
          key: 'product',
          filters: Object.keys(products).map(key => ({ text: t(`contractProduct:${key}`), value: key })),
          onFilter: (value: any, contract: any) => contract.product.indexOf(String(value)) === 0,
          render: (contract: any) => t(`contractProduct:${contract.product}`)
        },
        {
          title: t('contractsPage:date'),
          key: 'date',
          render: (contract: Contract) => dayjs(contract.date).format('L'),
          sorter: (a: any, b: any) => (a.date < b.date ? 0 : 1),
          sortDirections: ['descend', 'ascend']
        },
        {
          title: t('global:actions'),
          key: 'actions',
          render: (contract: Contract) => (
            <Button type="text" icon={<EditOutlined />} onClick={() => setContractInEdit(contract)} />
          ),
          authority: {
            role: ['accountant-admin']
          }
        }
      ] as (ColumnGroupType<Contract> | ColumnType<Contract>)[],
    []
  )
  const authorizedColumns = useAuthorizedData(columns)

  return (
    <div ref={contentEl}>
      {!!contractInEdit && (
        <UpdateContractFormModal
          handleCancel={() => {
            setContractInEdit(null)
          }}
          modalVisible={!!contractInEdit}
          contract={contractInEdit!}
        />
      )}
      <Table
        {...tableProps}
        dataSource={children}
        columns={authorizedColumns}
        rowKey={(record: Contract) => record.id.toString()}
        pagination={false}
      />
    </div>
  )
}

export default ContractTable
