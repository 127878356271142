import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProCard } from '@ant-design/pro-components'
import { Button, DatePicker, Space, Statistic } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import LoanGroup from './components/LoanGroup'
import LoanModal from './components/LoanModal'
import { setInvestmentPageDateAction } from '../../../redux/pages/investments/actions'
import {
  createInvestmentPageInvestmentsRequest,
  deleteInvestmentPageInvestmentsRequest,
  updateInvestmentPageInvestmentsRequest
} from '../../../redux/context/investments/actions'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { loansLoadingSelector } from '../../../redux/context/loans/selectors'
import InvestmentFormContextProvider from '../investments/InvestmentFormContextProvider'
import './_Loans.sass'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { LoanSummaryReport } from './types'
import { formatValueByType } from '../../../utils/helpers'
import { notLocked } from '../../settings/company/budgeting/budgetingScenarios/utils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import { AppDispatch } from '../../../redux/store'

interface InvestmentPageProps {
  loansData: LoanSummaryReport | undefined
  isExisting?: boolean
}
const { Divider } = ProCard

const InvestmentsPage: React.FC<InvestmentPageProps> = ({ loansData, isExisting }) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const location = useLocation()
  const navigate = useNavigate()
  const { date } = useSelector(investmentsPageSelector)
  const loansLoading = useSelector(loansLoadingSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const pagePath = location.pathname

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }
  const tabList = [
    {
      key: 'existing',
      label: t('menu:/budgeting/loans/existing')
    },
    {
      key: 'new',
      label: t('menu:/budgeting/loans/new')
    }
  ]

  return (
    <InvestmentFormContextProvider isDepreciable isExisting={!!isExisting}>
      <LoanModal
        visible={modalVisible}
        onFinish={toggleModal}
        updateInvestmentsRequest={updateInvestmentPageInvestmentsRequest}
        createInvestmentsRequest={createInvestmentPageInvestmentsRequest}
        deleteInvestmentsRequest={deleteInvestmentPageInvestmentsRequest}
        formType="investment"
      />

      <PageHeaderWrapper
        onTabChange={(key: string) => {
          navigate(key)
        }}
        fixedHeader
        extra={[
          <DatePicker
            value={date ? dayjs(date) : dayjs()}
            format="MM/YYYY"
            picker="month"
            key="month"
            onChange={val => dispatch(setInvestmentPageDateAction(val?.toString()))}
          />,
          <Button key="add-new" disabled={isExisting && !notLocked(budgetingScenario)} onClick={toggleModal}>
            <PlusOutlined />
            {t('global:add-new')}
          </Button>
        ]}
        content={
          <>
            <ProCard.Group direction="row">
              {location.pathname.split('/').pop() === 'existing' && (
                <>
                  <ProCard>
                    <Statistic
                      title={
                        location.pathname.split('/').pop() === 'new' ? t('loans:loan-count') : t('loans:actualBalance')
                      }
                      value={loansData?.summary.actualBalance}
                      suffix={t('global:currency-symbol')}
                      formatter={val => formatValueByType(val)}
                    />
                  </ProCard>
                  <Divider type="vertical" />
                </>
              )}
              <ProCard>
                <Statistic
                  title={
                    location.pathname.split('/').pop() === 'new' ? t('loans:total-new-loans') : t('loans:budgetBalance')
                  }
                  value={loansData?.summary.budgetBalance}
                  suffix={t('global:currency-symbol')}
                  formatter={val => formatValueByType(val)}
                />
              </ProCard>
              {location.pathname.split('/').pop() === 'existing' && (
                <>
                  <Divider type="vertical" />
                  <ProCard>
                    <Statistic
                      title={t('loans:difference')}
                      value={loansData?.summary.difference}
                      suffix={t('global:currency-symbol')}
                      formatter={val => formatValueByType(val)}
                    />
                  </ProCard>
                </>
              )}
            </ProCard.Group>
          </>
        }
        tabActiveKey={isExisting ? 'existing' : 'new'}
        tabList={tabList}
        title={t('loans:loans')}
        subTitle={t(`menu:${pagePath}`)}
        ghost={false}
      >
        <LoadingWrapper loading={!!loansLoading}>
          <Space style={{ width: '100%' }} size="large" direction="vertical">
            {loansData?.balanceSheetRows.map(balanceSheetRow => {
              return (
                balanceSheetRow.loans.length > 0 && (
                  <LoanGroup
                    balanceSheetRowId={balanceSheetRow.balanceSheetRowId}
                    key={balanceSheetRow.balanceSheetRowId}
                    loansByBalansheetRow={balanceSheetRow}
                    isExisting={isExisting}
                  />
                )
              )
            })}
          </Space>
        </LoadingWrapper>
      </PageHeaderWrapper>
    </InvestmentFormContextProvider>
  )
}

export default InvestmentsPage
