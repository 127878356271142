import * as Highcharts from 'highcharts'
import HighchartsCustomEvents from 'highcharts-custom-events'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { CustomEventsOptions } from '../../../../../config/highcharts/types'
import { Report } from '../../../../../redux/context/reports/types'
import { RequestError } from '../../../../../redux/error/types'
import ErrorComponent from '../../../../../components/Error/ErrorComponent'
import { useChartCategories, useChartSeries } from '../../../../../components/Chart/hooks'
import { useTableColumns } from '../../../../../redux/context/periodGroups/hooks'
import { setFiscalYearFilterEvent } from '../../../../../components/Chart/utils'
import './_KeyFigureChart.sass'
import { getPlotOptions } from '../../../../../components/Chart/plotOptions'
import Loading from '../../../../loading/Loading'

HighchartsExporting(Highcharts)
HighchartsCustomEvents(Highcharts as any)

interface KeyFigureChartProps {
  report: Report
  chartType?: 'column' | 'line' | 'spline' | 'stackedColumn' | 'groupedColumn'
  height?: number
  width?: number
  error?: RequestError
  cumulative?: boolean
}

const KeyFigureChart: React.FC<KeyFigureChartProps> = ({
  report,
  chartType,
  height,
  width,
  error,
  cumulative
}: KeyFigureChartProps) => {
  const { t } = useTranslation()
  // TODO: eristä graafityypit yhteen paikkaan
  const [graphType, setGraphType] = useState<
    'column' | 'line' | 'spline' | 'stackedColumn' | 'groupedColumn' | undefined
  >(chartType)
  const [specialColumnType, setSpecialColumnType] = useState<'stackedColumn' | 'groupedColumn' | undefined>(undefined)
  const columns = useTableColumns()
  const categories = useChartCategories()

  let actualType = !height ? graphType : chartType
  let actualSpecialType = specialColumnType

  if (actualType === 'stackedColumn' || actualType === 'groupedColumn') {
    actualSpecialType = actualType
    actualType = 'column'
  }

  const series: any[] = useChartSeries(report, { cumulative, chartType: actualType })

  const options: CustomEventsOptions = {
    chart: {
      height,
      width,
      animation: false
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b><br/>',
      valueSuffix: ' {point.suffix}',
      shared: false
    },
    plotOptions: getPlotOptions(actualType, actualSpecialType),
    xAxis: {
      categories,
      labels: {
        ...setFiscalYearFilterEvent(columns, report, useDispatch())
      }
    },
    yAxis: {
      title: {
        text: null
      }
    },
    series
  }

  const selectGraphType = (e: any) => {
    if (e === 'stackedColumn' || e === 'groupedColumn') {
      setGraphType('column')
      setSpecialColumnType(e)
    } else {
      setGraphType(e)
      setSpecialColumnType(undefined)
    }
  }

  return (
    <div className="chart-container">
      {error ? (
        <div style={{ padding: 16 }}>
          <ErrorComponent error={error} />
        </div>
      ) : (
        <div>
          {!height && (
            // TODO: oma komponentti
            <div className="tool-bar" style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
              <Select
                style={{ width: 200, marginBottom: 5 }}
                defaultValue={graphType}
                onChange={(e: any) => selectGraphType(e)}
              >
                {['column', 'line', 'spline', 'stackedColumn', 'groupedColumn'].map(type => (
                  <Select.Option key={type} value={type}>
                    {t(`global:${type}`)}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}

          {categories.find(c => c.includes('undefined')) ? (
            <Loading />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      )}
    </div>
  )
}

export default KeyFigureChart
