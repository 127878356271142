import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import Highcharts from 'highcharts'
import { currentUserHomepageSelector, currentUserSelector } from './redux/session/currentUser/selectors'
import { useTheme } from './config/highcharts/Theme'
import { useAuth } from './utils/hooks/useAuth'
import useGa from './utils/hooks/useGa'
import 'normalize.css'
import './App.sass'

const App: React.FC = () => {
  useGa()
  Highcharts.setOptions(useTheme())
  const currentUser = useSelector(currentUserSelector)
  i18next.loadLanguages(['en', 'sv', 'fi'])

  const { isLoggedIn } = useAuth()!
  const location = useLocation()
  const homepage = useSelector(currentUserHomepageSelector)

  useEffect(() => {
    const { id } = currentUser || {}
    id && ReactGA.set({ userId: id.toString() })
  }, [currentUser])

  if (isLoggedIn) {
    if (location.pathname === '/') {
      return <Navigate to={homepage} />
    }

    if (location?.state?.from) {
      return <Navigate to={location?.state?.from?.pathname || homepage} />
    }
  }

  if (!isLoggedIn) {
    if (location.pathname === '/' && location?.state?.from?.pathname !== '/') {
      return <Navigate to="/user/login" state={{ from: { pathname: '/' } }} />
    }

    if (!location.pathname.startsWith('/user')) {
      return <Navigate to="/user/login" state={{ from: location }} />
    }
  }

  return <Outlet />
}

export default App
