import React, { ReactNode } from 'react'
import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom'
import { Route } from './routes/Route'
import routeConfig from './routes/routeConfig'
import 'normalize.css'
import './App.sass'
import { filterRoute } from './utils/helpers'

const cloneRoute = (route: Route): ReactNode => {
  return React.cloneElement(route.element, { route: filterRoute(route, r => !!r.path) })
}

const map = (route: Route): RouteObject => ({
  path: route.path,
  index: route.index,
  handle: { ...route.handle, authority: route.authority },
  loader: route.loader,
  errorElement: route.errorElement,
  action: route.action,
  element: route?.element ? cloneRoute(route) : undefined,
  children: route?.routes?.map(map)
})

const AppRouter: React.FC = () => {
  const routes = routeConfig.map(map)
  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} />
}

export default AppRouter
