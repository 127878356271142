import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, TableColumnsType } from 'antd'
import { useColumnSearchProps } from '../../../../components/Table/useColumSearchProps'
import { sessionContractUserSelector } from '../../../../redux/session/contract/selectors'
import { Contract, ContractProduct } from '../../../../types/contract/Contract'
import { useTableProps } from '../../../../utils/hooks'
import { filterSalesUsers } from '../utils'

interface SuperUserContractTableProps {
  contracts: Contract[]
}

const SuperUserContractTable: React.FC<SuperUserContractTableProps> = ({ contracts }) => {
  const { t } = useTranslation()
  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl, 900)
  const sessionContractUsers = useSelector(sessionContractUserSelector)

  const columns: TableColumnsType<Contract> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Parent ID',
      key: 'parentId',
      dataIndex: 'parentId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.parentId || 0) - (b?.parentId || 0),
      render: (id: number) => {
        return <Link to={`/superuser/contracts/${id}`}>{id}</Link>
      }
    },
    {
      title: 'Y-tunnus',
      key: 'companyId',
      dataIndex: 'companyId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.companyId?.localeCompare(b?.companyId || '') || 0,
      ...useColumnSearchProps('companyId')
    },
    {
      title: 'Nimi',
      key: 'name',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.name?.localeCompare(b?.name || '') || 0,
      ...useColumnSearchProps('name')
    },
    {
      title: 'Tyyppi',
      key: 'type',
      dataIndex: 'type',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.type?.localeCompare(b?.type || '') || 0,
      filters: [...new Set(contracts.map(c => c.type))].map(value => ({
        text: value,
        value
      })),
      onFilter: (value, record) => record.type === value
    },
    {
      title: 'Tuote',
      key: 'product',
      dataIndex: 'product',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.product?.localeCompare(b?.product || '') || 0,
      render: (product: ContractProduct) => t(`contractProduct:${product}`),
      filters: Object.entries(ContractProduct).map(([value]) => ({
        text: t(`contractProduct:${value}`),
        value
      })),
      onFilter: (value, record) => record.product.startsWith(`${value}`)
    },
    {
      title: 'Myyjä',
      key: 'sellerId',
      dataIndex: 'sellerId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.sellerId || 0) - (b?.sellerId || 0),
      render: (sellerId: number) => sessionContractUsers.find(s => s.id === sellerId)?.displayName,
      filters: filterSalesUsers(sessionContractUsers).map(s => ({
        text: s.displayName,
        value: s.id
      })),
      onFilter: (value, record) => record.sellerId === value
    },
    {
      title: 'Hinta',
      key: 'price',
      dataIndex: 'price',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a?.price || 0) - (b?.price || 0)
    },
    {
      title: 'Layout',
      key: 'layout',
      dataIndex: 'layout',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.layout?.localeCompare(b?.layout || '') || 0,
      filters: [...new Set(contracts.map(c => c.layout))].map(value => ({
        text: value,
        value
      })),
      onFilter: (value, record) => record.layout === value
    },
    {
      title: 'Franchise',
      dataIndex: 'franchise',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.franchise?.localeCompare(b?.franchise || '') || 0,
      filters: [...new Set(contracts.map(c => c.franchise))].map(value => ({
        text: value,
        value
      })),
      onFilter: (value, record) => record.franchise === value
    },
    {
      title: 'Domain',
      key: 'domain',
      dataIndex: 'domain',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.domain?.localeCompare(b?.domain || '') || 0,
      filters: [...new Set(contracts.map(c => c.domain))].map(value => ({
        text: value,
        value
      })),
      onFilter: (value, record) => record.domain === value
    },
    {
      title: 'Luotu',
      key: 'date',
      dataIndex: 'date',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a?.date?.localeCompare(b?.date || '') || 0
    },
    {
      title: 'Toiminnot',
      key: 'actions',
      fixed: 'right',
      render: ({ id }: Contract) => {
        return <Link to={`/superuser/contracts/${id}`}>Avaa</Link>
      }
    }
  ]

  return (
    <div ref={contentEl}>
      <Table
        {...tableProps}
        rowKey={record => record.id.toString()}
        size="small"
        dataSource={contracts || []}
        columns={columns}
      />
    </div>
  )
}

export default SuperUserContractTable
