import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import EBITDAF from './EBITDAF'
import NetAssetsValue from './NetAssetValue'
import ProfitMultiplier from './ProfitMultiplier'
import { getValuationRequest } from '../../redux/context/reports/actions'
import { getValuationAdjustmentRequest } from '../../redux/context/valuation/actions'
import { valuationAdjustmentsSelector } from '../../redux/context/valuation/selectors'
import { valuationLoadingSelector } from '../../redux/context/reports/selectors'
import { contextRequest } from '../../redux/context/actions'
import LoadingWrapper from '../../components/Misc/LoadingWrapper'
import { errorSelector } from '../../redux/error/selectors'
import ErrorComponent from '../../components/Error/ErrorComponent'
import { filtersSelector } from '../../redux/context/filters/selectors'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/PageHeaderWrapper'
import './_Valuation.sass'
import { AppDispatch } from '../../redux/store'

const Valuation: React.FC = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState('netAssetValue')
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch = useDispatch<AppDispatch>()
  const { budgetingScenario, dimensions, periodGroups } = useSelector(filtersSelector)
  const valuationAdjustments = useSelector(valuationAdjustmentsSelector)
  const loading = useSelector(valuationLoadingSelector)
  const errors = useSelector(errorSelector)

  useEffect(() => {
    if (companyId) {
      dispatch(contextRequest(getValuationAdjustmentRequest))
      dispatch(contextRequest(getValuationRequest))
    }
  }, [companyId, budgetingScenario, dimensions, periodGroups, dispatch])

  const handleTabChange = (key: string) => {
    setCurrentPage(key)
  }

  const tabList = [
    {
      key: 'netAssetValue',
      label: t('valuationPage:net-asset-value')
    },
    {
      key: 'profitMultiplier',
      label: t('valuationPage:profit-multiplier')
    },
    {
      key: 'EBITDAF',
      label: t('valuationPage:EBITDAF')
    }
  ]

  const getPageComponent = () => {
    switch (currentPage) {
      case 'netAssetValue':
        return <NetAssetsValue valuationAdjustments={valuationAdjustments} />
      case 'profitMultiplier':
        return <ProfitMultiplier valuationAdjustments={valuationAdjustments} />
      case 'EBITDAF':
        return <EBITDAF valuationAdjustments={valuationAdjustments} />
      default:
        return null
    }
  }

  return (
    <PageHeaderWrapper
      ghost={false}
      title={t('valuationPage:value-evaluation')}
      tabList={tabList}
      tabActiveKey={currentPage}
      onTabChange={handleTabChange}
      breadcrumb={undefined}
    >
      <LoadingWrapper loading={loading}>
        {errors?.valuation ? <ErrorComponent error={errors?.valuation} /> : getPageComponent()}
      </LoadingWrapper>
    </PageHeaderWrapper>
  )
}
export default Valuation
