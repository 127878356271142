import { SetAppConfigsAction } from '../types'

export type LiabilityMapping = Record<number, number>

export const LIABILITY_MAPPING = 'LIABILITY_MAPPING'

export interface SetLiabilytyMappingAction {
  type: typeof LIABILITY_MAPPING
  payload: LiabilityMapping
}

export type LiabilityMappingActionTypes = SetLiabilytyMappingAction | SetAppConfigsAction
