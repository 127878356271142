import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

const useGa = () => {
  const location = useLocation()

  useEffect(() => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    }
  }, [])

  React.useEffect(() => {
    // Google Analytics
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }, [location])
}

export default useGa
