import { ConfigDictionary, SetAppConfigsAction } from '../types'

export interface FinancialStatementConfig {
  acceleratedDeprecationDeltaRowIds: ConfigDictionary<number[]>
  acceleratedDeprecationRowId: ConfigDictionary<number>
  otherSocialSecurityExpensesRowId: ConfigDictionary<number>
  pensionExpensesRowId: ConfigDictionary<number>
  plannedDeprecationRowId: ConfigDictionary<number>
  revenueRowId: ConfigDictionary<number>
  wagesRowId: ConfigDictionary<number>
  longTermLiabilityRowIds: ConfigDictionary<number>
  financialExpenseRowId: ConfigDictionary<number>
}

export const SET_FINANCIAL_STATEMENT_CONFIG = 'SET_FINANCIAL_STATEMENT_CONFIG'

export interface SetFinancialStatementConfigAction {
  type: typeof SET_FINANCIAL_STATEMENT_CONFIG
  payload: FinancialStatementConfig
}

export type BudgetSimulationFormItem = { name: string; configKey: string }

export type FinancialStatementConfigActionTypes = SetFinancialStatementConfigAction | SetAppConfigsAction
